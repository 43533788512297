import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Layout } from '@/layout/Layout';
import Seo from '../components/common/Seo';
import BlogPostCard from '../components/common/BlogPostCard';
import RightArrow from '../assets/icons/right-arrow-long-green.inline.svg';

import { formatDate } from '../utils';

const BlogPage = ({ data }) => {
  const { articles } = data.directus;
  const [ mainPost, ...listArticles ] = articles
  const mainPostImage = getImage(mainPost.image.imageFile);

  return (
    <Layout pageId="blog">
      <div className="container mt-16 lg:mt-20">
        <div className="py-8 text-center md:py-16">
          <h1 className="text-3xl font-bold md:text-6xl">El blog</h1>
        </div>

        <div className="row justify-center border-b-2 border-[#F5F5F5] pb-16">
          <div className="mb-8 lg:col-5 lg:mb-0">
            <div className="relative">
              <GatsbyImage image={mainPostImage} className="overflow-hidden rounded-2xl w-full" alt="" />
              <span className="absolute left-3 top-4 block rounded-full bg-black bg-opacity-20 px-4 py-1 text-white">
                {mainPost.category}
              </span>
            </div>
          </div>
          <div className="text-left lg:col-5">
            <h2 className="mb-4 text-3xl font-bold md:text-4xl">{mainPost.title}</h2>
            <p className="mb-10 md:mb-6">{mainPost.description}</p>
            <p className="mb-6">{formatDate(mainPost.publish_date)}</p>
            <Link
              className="my-3 flex items-center gap-2 font-bold text-[#00AAA6] max-w-fit"
              to={`/blog/${mainPost.slug}`}
            >
              Conoce más
              <RightArrow />
            </Link>
          </div>
        </div>
      </div>

      <div className="container pb-16">
        <div className="row">
          <div className="mx-auto flex py-8 md:col-10 md:justify-end md:py-9">
            <span>
              {listArticles.length} de {listArticles.length} artículos
            </span>
          </div>
          <div className="mx-auto grid gap-6 lg:col-10 md:grid-cols-2 lg:grid-cols-3 md:gap-x-8 md:gap-y-10">
            {listArticles.map((article) => (
              <BlogPostCard
                title={article.title}
                description={article.description}
                category={article.category}
                date={article.publish_date}
                link={`/blog/${article.slug}`}
                image={getImage(article.image.imageFile)}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head = () => <Seo title="Blog | Hisense" />;

export const query = graphql`
  query BlogPageQuery {
    directus {
      articles(sort: "-publish_date") {
        id
        title
        slug
        category
        publish_date
        description
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        vertical_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default BlogPage;
