import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const Seo = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          favicon
        }
      }
    }
  `);

  const { favicon } = data.site.siteMetadata;

  return (
    <>
      <title>{title}</title>
      <link rel="icon" type="image/svg+xml" href={favicon} />
    </>
  );
};

export default Seo;
