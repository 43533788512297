import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { formatDate } from "../../utils";
import RightArrow from "../../assets/icons/right-arrow-long-green.inline.svg"

interface Props {
  title: string;
  description: string;
  category: string;
  date: string;
  link: string;
  image: IGatsbyImageData;
  className: string;
}

const BlogPostCard = (props: Props) => {
  const { title, description, category, date, image, link, className } = props;

  return (
    <div className={className}>
      <div className="relative">
        <GatsbyImage image={image} alt={title} className="mb-6 h-[200px] overflow-hidden rounded-2xl" />
        <span className="absolute left-3 top-4 block rounded-full bg-black bg-opacity-20 px-4 py-1 text-white">
          {category}
        </span>
      </div>
      <h2 className="mb-4 line-clamp-2 text-2xl font-bold">{title}</h2>
      <p className="mb-4 line-clamp-3">{description}</p>
      <p className="mb-2">{formatDate(date)}</p>
      <Link className="my-3 flex items-center gap-2 font-bold text-[#00AAA6]" to={link}>
        Conoce más
        <RightArrow />
      </Link>
    </div>
  );
};

export default BlogPostCard;
